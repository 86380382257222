import type { GetMergeRequestsQuery, GetMergeRequestsResponse } from '@clsplus/api-types/entity-merge'

import { getTokens } from '../../helpers/auth'

function getMergeRequests(query: GetMergeRequestsQuery): Promise<GetMergeRequestsResponse> {
  const tokens = getTokens()
  const url = new URL(`${import.meta.env.VITE_API_URL}entity-merge/merge-requests`)

  if (query) {
    Object.entries(query).forEach(([key, value]) => url.searchParams.append(key, value.toString()))
  }

  return fetch(url.toString(), {
    headers: { Authorization: `Bearer ${tokens?.accessToken}` },
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        throw new Error(data.error)
      }

      return data
    })
}

export const mergeRequestsQuery = (queryParams: GetMergeRequestsQuery) => ({
  queryKey: ['merge-requests', queryParams],
  queryFn: () => getMergeRequests(queryParams),
})
