import {
  Button,
  Flex,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { QueryClient } from '@tanstack/react-query'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import type { LoaderFunctionArgs } from 'react-router-dom'
import { useLoaderData, useParams, useRevalidator } from 'react-router-dom'

import { mergeRequestsQuery } from '../../../api/get-merge-requests'
import { undoMergeRequest } from '../../../api/undo-merge-request'
import { DetailValue } from '../../../components/detail-value'
import { HasMCAccess, MCRequiredLevelEnum } from '../../../helpers/auth'
import { mergeEntityTypes, mergeStatuses } from '../../../reference'
import { NonIdealState } from '../../../ui/non-ideal-state'

export const loader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    return await queryClient.ensureQueryData(mergeRequestsQuery({ id: params.mergeRequestId || '' }))
  }

export default function MergeRequestDetails() {
  const params = useParams()
  const queryClient = useQueryClient()
  const revalidator = useRevalidator()
  const initialData = useLoaderData() as Awaited<ReturnType<ReturnType<typeof loader>>>

  const { isOpen, onOpen, onClose } = useDisclosure()

  const mergeRequestId = params.mergeRequestId || ''

  const { data: mergeRequests } = useQuery({
    ...mergeRequestsQuery({ id: mergeRequestId }),
    initialData,
  })

  const undoMergeRequestMutation = useMutation({
    mutationFn: () => undoMergeRequest(mergeRequestId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['merge-requests'] })
      revalidator.revalidate()
    },
    onSettled: () => onClose(),
  })

  const mergeRequest = mergeRequests?.[0]

  if (!mergeRequest) {
    return (
      <NonIdealState
        title="Merge request not found"
        description="We couldn't find a merge request with the specified ID."
        iconColor="primary.400"
        icon={['fas', 'face-confused']}
      />
    )
  }

  const allowUndoMerge = !mergeRequest.undoDependencies?.length && mergeRequest.status === 'MERGE_COMPLETE'

  return (
    <>
      <Flex w="100%" justifyContent="space-between" alignItems="baseline">
        <Heading as="h2" size="lg">
          {mergeRequest.mergeToName}
        </Heading>
        {allowUndoMerge && (
          <HasMCAccess minLevel={MCRequiredLevelEnum['clsp-superadmin']}>
            <Button
              variant="warning"
              minW={32}
              leftIcon={<Icon as={FontAwesomeIcon} icon={['fas', 'rotate-left']} />}
              onClick={onOpen}
            >
              Undo merge
            </Button>
          </HasMCAccess>
        )}
      </Flex>
      <Stack w="100%" mt={6} spacing={6}>
        <DetailValue label="ID" value={mergeRequest.id} />
        <DetailValue
          label="Entity type"
          value={mergeEntityTypes.find(({ id }) => id === mergeRequest.entityType)?.name}
        />
        <DetailValue label="Status" value={mergeStatuses.find(({ id }) => id === mergeRequest.status)?.name} />
        {mergeRequest.failedReason !== undefined && (
          <DetailValue label="Failed Reason" value={mergeRequest.failedReason} />
        )}
        <DetailValue label="Merge from ID" value={mergeRequest.mergeFrom} />
        <DetailValue label="Merge to ID" value={mergeRequest.mergeTo} />
      </Stack>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm undo merge</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to undo this merge?</Text>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="warning"
              mr={3}
              onClick={() => undoMergeRequestMutation.mutate()}
              isLoading={undoMergeRequestMutation.isLoading}
            >
              Undo merge
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
