import {
  Button,
  Code,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  InputGroup,
  InputRightElement,
  Link,
  Select,
  Stack,
} from '@chakra-ui/react'
import type { GetMergeRequestsResponse, MergeEntityType, MergeStatus } from '@clsplus/api-types/entity-merge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { QueryClient } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import type { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import type { LoaderFunctionArgs } from 'react-router-dom'
import { Link as RouterLink, useLoaderData, useSearchParams } from 'react-router-dom'

import { mergeRequestsQuery } from '../../../api/get-merge-requests'
import { ListPageFilters } from '../../../components/list-page-filters'
import { HasMCAccess, MCRequiredLevelEnum } from '../../../helpers/auth'
import { formatFullDate, formatShortDate } from '../../../helpers/datetime'
import { mergeEntityTypes, mergeStatuses } from '../../../reference'
import { DatePicker } from '../../../ui/date-picker'
import { Input } from '../../../ui/input'
import { NonIdealState } from '../../../ui/non-ideal-state'
import { Table } from '../../../ui/table'

type MergeRequestsFilters = {
  id: string
  entityType: MergeEntityType
  status: MergeStatus
  mergeFrom: string
  mergeTo: string
  createdBy: string
  undoneBy: string
  createdStart: Date | null
  createdEnd: Date | null
  undoneStart: Date | null
  undoneEnd: Date | null
}

export const loader =
  (queryClient: QueryClient) =>
  async ({ request }: LoaderFunctionArgs) => {
    const urlParams = Object.fromEntries(new URL(request.url).searchParams)
    return await queryClient.ensureQueryData(mergeRequestsQuery(urlParams))
  }

export default function MergeRequests() {
  const [searchParams, setSearchParams] = useSearchParams()

  const params = Object.fromEntries(searchParams)
  const paramsCount = Object.keys(params).length

  const initialMergeRequestsData = useLoaderData() as Awaited<ReturnType<ReturnType<typeof loader>>>

  const { data: mergeRequests, ...mergeRequestsQueryResult } = useQuery({
    ...mergeRequestsQuery(params),
    initialData: initialMergeRequestsData,
  })

  const filtersFormDefaultValues = {
    id: params.id ?? '',
    entityType: (params.entityType as MergeEntityType) ?? '',
    status: (params.status as MergeStatus) ?? '',
    mergeFrom: params.mergeFrom ?? '',
    mergeTo: params.mergeTo ?? '',
    createdBy: params.createdBy ?? '',
    undoneBy: params.undoneBy ?? '',
    createdStart: params.createdStart ? new Date(params.createdStart) : null,
    createdEnd: params.createdEnd ? new Date(params.createdEnd) : null,
    undoneStart: params.undoneStart ? new Date(params.undoneStart) : null,
    undoneEnd: params.undoneEnd ? new Date(params.undoneEnd) : null,
  }

  const { control, register, handleSubmit, reset } = useForm<MergeRequestsFilters>({
    values: filtersFormDefaultValues,
  })

  const handleDismissFilters = () => reset(filtersFormDefaultValues)

  const handleSubmitClick = (formData: MergeRequestsFilters) => {
    const newSearchParams = Object.entries(formData).reduce<Record<string, string>>((acc, [key, val]) => {
      if (val instanceof Date) {
        return { [key]: formatShortDate(val), ...acc }
      }

      return {
        ...(val && { [key]: val }),
        ...acc,
      }
    }, {})

    setSearchParams(newSearchParams)
  }

  const handleResetClick = () => {
    reset(filtersFormDefaultValues)
    setSearchParams({})
  }

  const columns: ColumnDef<GetMergeRequestsResponse[number]>[] = useMemo(
    () => [
      {
        accessorKey: 'mergeToName',
        header: 'Name',
        cell: ({ row }) => (
          <Link as={RouterLink} to={row.original.id}>
            {row.original.mergeToName ?? 'Unknown'}
          </Link>
        ),
      },
      {
        accessorKey: 'entityType',
        header: 'Entity type',
        cell: ({ row }) => mergeEntityTypes.find(({ id }) => id === row.original.entityType)?.name ?? 'Unknown',
      },
      {
        accessorKey: 'status',
        header: 'Status',
        cell: ({ row }) => mergeStatuses.find(({ id }) => id === row.original.status)?.name ?? 'Unknown',
      },
      {
        accessorKey: 'id',
        header: 'ID',
      },
      {
        accessorKey: 'mergeFrom',
        header: 'Merge from ID',
      },
      {
        accessorKey: 'mergeTo',
        header: 'Merge to ID',
      },
      {
        accessorKey: 'createdBy',
        header: 'Created by ID',
      },
      {
        accessorKey: 'createdDate',
        header: 'Created date',
        cell: ({ row }) => row.original.createdDate && formatFullDate(new Date(row.original.createdDate)),
      },
    ],
    []
  )

  return (
    <>
      <Heading as="h2" size="lg">
        Merge requests
      </Heading>
      <Stack w="100%" spacing={8} my={6}>
        <Flex justifyContent="space-between">
          <ListPageFilters
            formId="merge-requests-filters"
            onDismiss={handleDismissFilters}
            onReset={handleResetClick}
            onSubmit={handleSubmit(handleSubmitClick)}
            title="Filter merge requests"
          >
            <FormControl>
              <FormLabel>ID</FormLabel>
              <Input {...register('id')} />
            </FormControl>
            <FormControl>
              <FormLabel>Entity type</FormLabel>
              <Select {...register('entityType')}>
                <option value="">-</option>
                {mergeEntityTypes.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Merge status</FormLabel>
              <Select {...register('status')}>
                <option value="">-</option>
                {mergeStatuses.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Merge from ID</FormLabel>
              <Input {...register('mergeFrom')} />
            </FormControl>
            <FormControl>
              <FormLabel>Merge to ID</FormLabel>
              <Input {...register('mergeTo')} />
            </FormControl>
            <FormControl>
              <FormLabel>Created by ID</FormLabel>
              <Input {...register('createdBy')} />
            </FormControl>
            <FormControl>
              <FormLabel>Undone by ID</FormLabel>
              <Input {...register('undoneBy')} />
            </FormControl>
            <FormControl>
              <FormLabel>Created from</FormLabel>
              <Controller
                control={control}
                name="createdStart"
                render={({ field: { onChange, value } }) => (
                  <InputGroup zIndex={10}>
                    <Input as={DatePicker} selected={value} onChange={onChange} dateFormat="dd/MM/yyyy" />
                    <InputRightElement pointerEvents="none">
                      <Icon as={FontAwesomeIcon} icon={['fad', 'calendar-days']} color="primary.400" />
                    </InputRightElement>
                  </InputGroup>
                )}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Created to</FormLabel>
              <Controller
                control={control}
                name="createdEnd"
                render={({ field: { onChange, value } }) => (
                  <InputGroup zIndex={10}>
                    <Input as={DatePicker} selected={value} onChange={onChange} dateFormat="dd/MM/yyyy" />
                    <InputRightElement pointerEvents="none">
                      <Icon as={FontAwesomeIcon} icon={['fad', 'calendar-days']} color="primary.400" />
                    </InputRightElement>
                  </InputGroup>
                )}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Undone from</FormLabel>
              <Controller
                control={control}
                name="undoneStart"
                render={({ field: { onChange, value } }) => (
                  <InputGroup zIndex={10}>
                    <Input as={DatePicker} selected={value} onChange={onChange} dateFormat="dd/MM/yyyy" />
                    <InputRightElement pointerEvents="none">
                      <Icon as={FontAwesomeIcon} icon={['fad', 'calendar-days']} color="primary.400" />
                    </InputRightElement>
                  </InputGroup>
                )}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Undone to</FormLabel>
              <Controller
                control={control}
                name="undoneEnd"
                render={({ field: { onChange, value } }) => (
                  <InputGroup zIndex={10}>
                    <Input as={DatePicker} selected={value} onChange={onChange} dateFormat="dd/MM/yyyy" />
                    <InputRightElement pointerEvents="none">
                      <Icon as={FontAwesomeIcon} icon={['fad', 'calendar-days']} color="primary.400" />
                    </InputRightElement>
                  </InputGroup>
                )}
              />
            </FormControl>
          </ListPageFilters>
          <HasMCAccess minLevel={MCRequiredLevelEnum['clsp-superadmin']}>
            <Button as={RouterLink} to="create" variant="primary" leftIcon={<FontAwesomeIcon icon={['fas', 'plus']} />}>
              New merge request
            </Button>
          </HasMCAccess>
        </Flex>
        {mergeRequestsQueryResult.isError ? (
          <NonIdealState
            title="Error fetching merge requests"
            description="Here's what we know:"
            iconColor="red.600"
            icon={['fad', 'triangle-exclamation']}
          >
            <Code mt={4} px={6} py={4} borderRadius="6px" bg="primary.600">
              {mergeRequestsQueryResult.error instanceof Error
                ? mergeRequestsQueryResult.error.message
                : 'An unknown error occurred. Please contact a system administrator.'}
            </Code>
            <Button
              mt={4}
              variant="secondary"
              leftIcon={<Icon as={FontAwesomeIcon} icon={['fas', 'xmark-large']} />}
              onClick={handleResetClick}
            >
              Reset filters
            </Button>
          </NonIdealState>
        ) : mergeRequests?.length ? (
          <Table columns={columns} data={mergeRequests} />
        ) : (
          <NonIdealState
            title="No merge requests found"
            description={
              paramsCount > 0
                ? "We couldn't find any merge requests matching your filters"
                : "We couldn't find any merge requests"
            }
            iconColor="primary.400"
            icon={['fas', 'face-confused']}
          >
            {paramsCount > 0 && (
              <Button
                mt={4}
                variant="secondary"
                leftIcon={<Icon as={FontAwesomeIcon} icon={['fas', 'xmark-large']} />}
                onClick={handleResetClick}
              >
                Reset filters
              </Button>
            )}
          </NonIdealState>
        )}
      </Stack>
    </>
  )
}
