import type { CreateOrUndoEntityMergeResponse } from '@clsplus/api-types/entity-merge'

import { getTokens } from '../../helpers/auth'

export function undoMergeRequest(mergeRequestId: string): Promise<CreateOrUndoEntityMergeResponse> {
  const tokens = getTokens()
  return fetch(`${import.meta.env.VITE_API_URL}entity-merge/merge-requests/${mergeRequestId}/undo`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${tokens?.accessToken}`,
    },
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        throw new Error(data.error)
      }

      return data
    })
}
